<template>
  <svg
    width="14"
    height="24"
    viewBox="0 0 14 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.875 22.875C7.875 23.4984 7.37344 24 6.75 24C6.12656 24 5.625 23.4984 5.625 22.875V13.4062C2.43281 12.8719 0 10.0969 0 6.75C0 3.02203 3.02203 0 6.75 0C10.4766 0 13.5 3.02203 13.5 6.75C13.5 10.0969 11.0672 12.8719 7.875 13.4062V22.875ZM6.75 2.25C4.22344 2.25 2.25 4.26469 2.25 6.75C2.25 9.23438 4.22344 11.25 6.75 11.25C9.23438 11.25 11.25 9.23438 11.25 6.75C11.25 4.26469 9.23438 2.25 6.75 2.25Z"
      fill="#191617"
    />
  </svg>
</template>
